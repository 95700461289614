<template>
  <div class="large-page">
    <!-- show the breadcrumbs and the button to trigger form -->
    <breadcrumbs>
      <div class="d-flex flex-grow-1 justify-end align-center">
        <v-btn
          fab
          x-small
          depressed
          color="white"
          @click="triggerCreateForm"
        >
          <v-icon color="secondary">
            add
          </v-icon>
        </v-btn>
      </div>
    </breadcrumbs>

    <!-- show the API results -->
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      class="mt-4 box-shadow-soft"
    >
      <template v-slot:item.kpi="{ item }">
        <span>
          {{ `${item.kpi}%` }}
        </span>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          small
          depressed
          color="primary"
          @click="triggerEditForm(item)"
        >
          Edit

          <v-icon right>
            edit
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- show the dialog form -->
    <v-dialog
      v-model="shouldShowDialog"
      max-width="500"
    >
      <v-card
        :loading="isLoading"
      >
        <v-card-text class="pt-4">
          <v-form
            :disabled="isLoading"
          >
            <v-row class="mt-0">
              <!-- 1. Platform -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.platform"
                  :items="options.platform"
                  :hide-details="!$v.form.platform.$anyError"
                  :error-messages="$v.form.platform.$anyError ? ['Please select a platform'] : null"
                  @blur="$v.form.platform.$touch"
                  label="Platform"
                  outlined
                  dense
                />
              </v-col>
              <!-- 2. Category -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.category"
                  :items="options.category"
                  :hide-details="!$v.form.category.$anyError"
                  :error-messages="$v.form.category.$anyError ? ['Please select a category'] : null"
                  @blur="$v.form.category.$touch"
                  label="Category"
                  outlined
                  dense
                />
              </v-col>
              <!-- 3. Format -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.format"
                  :items="options.format"
                  :hide-details="!$v.form.format.$anyError"
                  :error-messages="$v.form.format.$anyError ? ['Please select a format'] : null"
                  @blur="$v.form.format.$touch"
                  label="Format"
                  outlined
                  dense
                />
              </v-col>
              <!-- 4. KPI -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.kpi"
                  :hide-details="!$v.form.kpi.$anyError"
                  :error-messages="$v.form.kpi.$anyError ? ['KPI value is required'] : null"
                  @blur="$v.form.kpi.$touch"
                  autocomplete="kpi-value"
                  label="KPI"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click="handleSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

const defaultFormData = () => ({
  platform: "",
  category: "",
  format: "",
  kpi: ""
})

export default {
  name: "AdminInfluencersKPI",

  components: {
    Breadcrumbs
  },

  data: () => ({
    // related to form operations
    isLoading: false,
    shouldShowDialog: false,
    form: defaultFormData(),

    options: {
      platform: ["Instagram", "Youtube", "TikTok"],
      category: ["Mega", "Macro", "Micro"],
      format: ["Story", "Reel", "IGTV", "Video", "Static"]
    },

    // API results
    tableItems: [],

    tableHeaders: [
      {
        text: "ID",
        value: "id",
        sortable: true
      },
      {
        text: "Platform",
        value: "platform",
        sortable: true
      },
      {
        text: "Category",
        value: "category",
        sortable: true
      },
      {
        text: "Format",
        value: "format",
        sortable: true
      },
      {
        text: "KPI",
        value: "kpi",
        sortable: true
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
      },
    ]
  }),

  validations: {
    form: {
      platform: {},
      category: {},
      format: {},
      kpi: {}
    }
  },

  methods: {
    /**
     * HTTP GET Request
     */
    async fetchItems() {
      // Create the loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const response = await axios({
          url: "/admin/influencer_kpis"
        })

        // otherwise push the results
        if (response.data.status) {
          this.tableItems = response.data.data
        }
      } catch (error) {
        // log using the helper function
        logger({ type: "Network Error", error })
      } finally {
        // close the loader
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Populate the current form and show the dialog
     *
     * @param {Object} item
     */
    triggerEditForm(item) {
      this.form = { ...item }

      this.shouldShowDialog = true
    },

    /**
     * Reset the current form and show the dialog
     */
    triggerCreateForm() {
      this.form = defaultFormData()

      this.shouldShowDialog = true
    },

    async handleCreate() {
      const response = await axios({
        url: "/admin/influencer_kpi/create",
        method: "POST",
        data: this.form
      })

      if (response.data.status) {
        this.$store.dispatch("toasts/add", { text: "Record added." })
      }
      else {
        this.$store.dispatch("toasts/add", { text: "An error occurred." })
      }
    },

    async handleUpdate() {
      const response = await axios({
        url: "/admin/influencer_kpi/update/" + this.form.id,
        method: "PUT",
        data: {
          platform: this.form.platform,
          category: this.form.category,
          format: this.form.format,
          kpi: this.form.kpi
        }
      })

      if (response.data.status) {
        this.$store.dispatch("toasts/add", { text: "Record updated." })
      }
      else {
        this.$store.dispatch("toasts/add", { text: "An error occurred." })
      }
    },

    async handleSave() {
      await this.$v.$touch()

      // if there's some validation error, do not continue
      if (this.$v.$anyError) return false

      // otherwise make the network request
      this.isLoading = true

      // Create the loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        // if there's an ID present, make update request
        if (this.form.id) {
          await this.handleUpdate()
        }
        else {
          await this.handleCreate()
        }

        // now refresh the table items
        this.fetchItems()

        // also hide the dialog
        this.form = defaultFormData()
        this.shouldShowDialog = false
      } catch (error) {
        logger({ type: "Influencers KPI Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred." })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  },

  mounted() {
    this.fetchItems()
  }
}
</script>
